import React from 'react'
import { FormattedMessage } from "react-intl";
import {
    leadMembers,
    frontendMembers,
    backendMembers,
    solidityMembers,
    marketingMembers,
    businessMembers, rndMembers
} from "../../../constants/constants";
import TeamCard from "./TeamCard";
import { graphql, useStaticQuery } from "gatsby";

import './TeamSection.scss';

const TeamSection = () => {
    //TODO: get paths to json files, extract everything from graphql
    const images = useStaticQuery(graphql`
        fragment Image on File {
            childImageSharp {
                    gatsbyImageData(
                        quality: 90
                        width: 200
                        height: 200
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                  }
        }
        query {
         stefanMiric: file(
            relativePath: { eq: "team/stefan-miric.jpg" }
         ) {
              ...Image
           }
         nikolaVukovic: file(
            relativePath: { eq: "team/nikola-vukovic.jpg" }
         ) {
              ...Image
           }
        nikolaKlipa: file(
            relativePath: { eq: "team/nikola-klipa.jpg" }
        ) {
              ...Image
          }
        nikolaSreckovic: file(
            relativePath: { eq: "team/nikola-sreckovic.jpg" }
        ) {
              ...Image
          }
        brankoVitorovic: file(
            relativePath: { eq: "team/branko-vitorovic.jpg" }
        ) {
              ...Image
          }
        nikolaJankovic: file(
            relativePath: { eq: "team/nikola-jankovic.jpg" }
        ) {
              ...Image
          }
        julijaDimitrijevic: file(
            relativePath: { eq: "team/julija-dimitrijevic.jpg" }
        ) {
              ...Image
          }
        andrejCvoro: file(
            relativePath: { eq: "team/andrej-cvoro.jpg" }
        ) {
              ...Image
          }
        nenadPalinkasevic: file(
            relativePath: { eq: "team/nenad-palinkasevic.jpg" }
        ) {
             ...Image
          }
        nebojsaMajkic: file(
            relativePath: { eq: "team/nebojsa-majkic.jpg" }
        ) {
              ...Image
          }
        aleksandarBegovic: file(
            relativePath: { eq: "team/aleksandar-begovic.jpg" }
        ) {
              ...Image
          }
        nikolaRoganovic: file(
            relativePath: { eq: "team/nikola-roganovic.jpg" }
        ) {
              ...Image
          }
        ilijaSmiljanic: file(
            relativePath: { eq: "team/ilija-smiljanic.jpg" }
        ) {
              ...Image
          }
        jelenaKoren: file(
            relativePath: { eq: "team/jelena-koren.jpg" }
        ) {
              ...Image
          }
        matijaPojatar: file(
            relativePath: { eq: "team/matija-pojatar.jpg" }
        ) {
              ...Image
          }
        pavlePrica: file(
            relativePath: { eq: "team/pavle-prica.jpg" }
        ) {
              ...Image
          }
        sandraRaickovic: file(
            relativePath: { eq: "team/sandra-raickovic.jpg" }
        ) {
              ...Image
          }
        zarkoStamenic: file(
            relativePath: { eq: "team/zarko-stamenic.jpg" }
        ) {
              ...Image
          }
        filipJosipovic: file(
            relativePath: { eq: "team/filip-josipovic.jpg" }
        ) {
              ...Image
          }
        rajkoZagorac: file(
            relativePath: { eq: "team/rajko-zagorac.jpg" }
        ) {
              ...Image
          }
        aleksaUrosevic: file(
            relativePath: { eq: "r&d/team/aleksa-urosevic.jpg" }
        ) {
              ...Image
          }
        viktorVujicic: file(
            relativePath: { eq: "r&d/team/viktor-vujicic.jpg" }
        ) {
              ...Image
          }
        aleksandarSpremo: file(
            relativePath: { eq: "r&d/team/aleksandar-spremo.jpg" }
        ) {
              ...Image
          }
        bojanAntunovic: file(
            relativePath: { eq: "r&d/team/bojan-antunovic.jpg" }
        ) {
              ...Image
          }
        ivanDzambasanovic: file(
            relativePath: { eq: "r&d/team/ivan-dzambasanovic.jpg" }
        ) {
              ...Image
          }
        aleksandarMilosavljevic: file(
            relativePath: { eq: "team/aleksandar-milosavljevic.jpg" }
        ) {
              ...Image
          }
        }
    `);

    return (
        <div className="team-section-wrapper padding center content">
            <h1 className="title">
                <FormattedMessage
                    id='meet_team'
                />
            </h1>
            <div className="no-title team-section">
                <div className="team-cards">
                    {leadMembers.map((member, i) => (
                        <TeamCard
                            member={member}
                            teamSection="boss"
                            img={images[member.image]}
                            key={`${i}${member.name}`}
                        />
                    ))}
                </div>
            </div>
            <div className="team-section">
                <span className="subsection-title">Frontend - UI - UX</span>
                <div className="team-cards">
                    {frontendMembers.map((member, i) => (
                        <TeamCard
                            member={member}
                            teamSection="frontend"
                            img={images[member.image]}
                            key={`${i}${member.name}`}
                        />
                    ))}
                </div>
            </div>
            <div className="team-section">
                <span className="subsection-title">Backend</span>
                <div className="team-cards">
                    {backendMembers.map((member,i) => (
                        <TeamCard
                            member={member}
                            teamSection="backend"
                            img={images[member.image]}
                            key={`${i}${member.name}`}
                        />
                    ))}
                </div>
            </div>
            <div className="team-section">
              <span className="subsection-title">Solidity</span>
              <div className="team-cards">
                {solidityMembers.map((member,i) => (
                  <TeamCard
                    member={member}
                    teamSection="backend"
                    img={images[member.image]}
                    key={`${i}${member.name}`}
                  />
                ))}
              </div>
            </div>
            <div className="team-section">
                <span className="subsection-title">Marketing & BizDev</span>
                <div className="team-cards">
                    {marketingMembers.map((member, i) => (
                        <TeamCard
                            member={member}
                            teamSection="marketing"
                            img={images[member.image]}
                            key={`${i}${member.name}`}
                        />
                    ))}
                </div>
            </div>
            <div className="team-section">
                <span className="subsection-title">Business Support</span>
                <div className="team-cards">
                    {businessMembers.map((member, i) => (
                        <TeamCard
                            member={member}
                            teamSection="marketing"
                            img={images[member.image]}
                            key={`${i}${member.name}`}
                        />
                    ))}
                </div>
            </div>
            <div className="team-section">
                <span className="subsection-title">R&D</span>
                <div className="team-cards">
                    {rndMembers.map((member, i) => (
                        <TeamCard
                            member={member}
                            teamSection="rnd"
                            img={images[member.image]}
                            key={`${i}${member.name}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TeamSection;
